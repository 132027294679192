import React, { Component, useEffect, useState } from 'react';
//import './assets/css/style.css';
import './assets/css/core.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Login from './components/Login';
import Body from './components/Body';
import Left from './components/Left';
import User from './components/User';
import NotFound from './NotFound';
import { Container, Row } from 'reactstrap';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Explore from './components/Explore';
import Notification from './components/Notification';
import Message from './components/Message';
import Transactions from './components/Transactions';
import Collections from './components/Collections';
import Profile from './components/Profile';
import Store from './components/Store';
import Categories from './components/Categories';
import ItemDetail from './components/ItemDetail';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import Products from './components/Products';
import Orders from './components/Orders';
import Post from './components/Post';
import Posts from './components/Posts';
import Apply from './components/Apply';
import Pages from './components/Pages';
import Renew from './components/Renew';
import Test from './components/Test';
import axios from 'axios';

import da from "../src/lang/da.json";
import de from "../src/lang/de.json";
import en from "../src/lang/en.json";
import es from "../src/lang/es.json";
import fr from "../src/lang/fr.json";
import it from "../src/lang/it.json";
import BrevoConversations from './components/BrevoConversations';

<link rel="icon" type="image/png" href="https://panel.kinkyfans.net/secret.webp" />

function loadStyleSheet(href) {
  // Daha önce eklenmiş bir stil dosyası varsa kaldır
  const existingLink = document.getElementById('dynamic-stylesheet');
  if (existingLink) {
    existingLink.parentNode.removeChild(existingLink);
  }

  // Yeni bir link etiketi oluştur ve stil dosyasının yolunu ayarla
  const link = document.createElement('link');
  link.id = 'dynamic-stylesheet';
  link.rel = 'stylesheet';
  link.href = href;
  document.head.appendChild(link);
}

function App() {
  let useripadd = "";
  let goster = 0;
  //let userid = sessionStorage.getItem('userid');
  //document.title = "KinkyFans";


  localStorage.setItem('stockimg', 'https://panel.kinkyfans.net/secret.webp');
  localStorage.setItem('dil', 'en');
  localStorage.setItem('localDocs', 'https://panel.kinkyfans.net');
  let dil = localStorage.getItem('dil');
  let mode = localStorage.getItem('mode');
  if (mode) { } else { localStorage.setItem('mode', 'dark'); mode = 'dark'; }
  if (mode == "dark") { import('./assets/css/style.css?v=1.0.3'); } else { import('./assets/css/style2.css?v=1.0.3'); }
  let localDocs = localStorage.getItem('localDocs');
  let mevcutsayfa = window.location.pathname;

  //const langData = dil;
  //if(langData){}else{langData = en;}
  //const [mode, setMode] = useState();


  const [onek, setOnek] = useState('');
  const [langData, setLangData] = useState(en);
  const [userData, setUserData] = useState([]);
  const fetchUserData = () => {
    fetch(localDocs + '/rn-adaptor/l-user?id=' + userid)
      .then(responseUD => responseUD.json())
      .then(userData => {
        setUserData(userData);
        if (userData.lang) {
          if (userData.lang == "en") { setLangData(en) }
          if (userData.lang == "da") { setLangData(da) }
          if (userData.lang == "de") { setLangData(de) }
          if (userData.lang == "es") { setLangData(es) }
          if (userData.lang == "fr") { setLangData(fr) }
          if (userData.lang == "it") { setLangData(it) }
          if (userData.mode) { localStorage.setItem('mode', userData.mode); }
        } else {
          setLangData(en);
        }
      })
      .catch(errorUD => console.log('Fetch error : ', errorUD));
  }
  /*const [langData, setLangData] = useState([]);const fetchLangData = () => {
    fetch(localDocs+'/rn-adaptor/lang2.php?lang=' + dil+'&mevcutsayfa='+mevcutsayfa)
    .then(rsLng => rsLng.json())
    .then(langData => {setLangData(langData);
    })
      .catch(errLng => console.log('Ferror : ', errLng));}
      */

  useEffect(() => {
    fetchUserData();
    //fetchLangData();
  }, []);
  /*useEffect(() => {
    const preventRightClick = (e) => {e.preventDefault(); };
    document.addEventListener("contextmenu", preventRightClick);
    return () => {document.removeEventListener("contextmenu", preventRightClick);};
  }, []);*/

  setTimeout(() => { document.getElementById('loadingScreen').style.display = 'none'; }, 1000);

  function ldMenu(type) {
    if (type == "close") {
      document.getElementById('ldMenu').style.left = "-300px";
      document.getElementById('windowBlur').style.display = "none";
    } else {
      document.getElementById('ldMenu').style.left = "0px";
      document.getElementById('windowBlur').style.display = "block";
    }
  }
  let userid = localStorage.getItem('userid');
  let mobilScreenDisplay = 'block';
  if (window.location.pathname == "/login") { mobilScreenDisplay = 'none'; } else { mobilScreenDisplay = 'block' }
  function logout() {
    localStorage.setItem('userid', '0');
    window.location.href = '/login';
  }
  const chData = async (userid, area, type, dil) => {
    let value1 = ""; let value2 = ""; let value3 = "";
    if (mode == "dark") { value1 = "light"; } else { value1 = "dark"; }
    const data = { userid: userid, area: area, value1: value1, value2: value2, value3: value3, type: type, dil: dil };
    //console.log(data);
    try {
      const response = await fetch(localDocs + '/rn-adaptor/o-chData.php', {
        method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
      });
      if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
      const responseJson = await response.json();
      //console.log("PHP tarafından dönen yanıt:", responseJson);
      if (type == "language") { window.location.href = "/profile"; }
      window.location.reload();
    } catch (error) { console.log("An error :", error); }
  };
  return (
    <div >
      
      <div id='loadingScreen' style={{ float: 'left', width: '100%', height: '100vh', backgroundColor: '#222', zIndex: '99999', position: 'fixed', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', overflow: 'hidden' }}>
        <div className="spinner" style={{ display: 'none!important', width: '150px', height: '150px', borderRadius: '50%', border: '5px solid rgba(0, 0, 0, 0.1)', borderTopColor: '#007bff', animation: 'spin 1s ease-in-out infinite' }}></div>
        <img style={{ width: '300px', height: '300px', objectFit: 'contain' }} src={require('../src/assets/images/klogo_dark.webp')} />
      </div>
      {userid > 0 ? (
        <div className='mobilScreen' style={{ display: mobilScreenDisplay }}>
          <div className='mobilMenuArea'>
            <div className='mobilMenuItems'><a href='/'><img src={require('../src/assets/icons/home.webp')} /></a></div>
            <div className='mobilMenuItems'><a href='/message'><img src={require('../src/assets/icons/message.webp')} /></a></div>
            <div className='mobilMenuItems'><a onClick={() => ldMenu('open')}><img src={require('../src/assets/icons/more.webp')} /></a></div>
            <div className='mobilMenuItems'><a href='/notification'><img src={require('../src/assets/icons/notifications.webp')} /></a></div>
            <div className='mobilMenuItems'><a href={'/user-detail/' + userid}><img src={require('../src/assets/icons/profile.webp')} /></a></div>
          </div>
        </div>
      ) : ''}
      <div className='leftDetailMenu' id='ldMenu'>
        <span className='ldmClose' onClick={() => ldMenu('close')}>X</span>
        <div className='ldUser'>
          <div className='ldUserInfo'>
            <img src={userData.pp} alt='User' />
            <p>{userData.name} {userData.surname}</p>
            <a>@{userData.username}</a>
          </div>
          <a href='' className='ldUserData'>
            <h6>{userData.following}</h6>
            <p>{langData.following}</p>
          </a>
          <a href='' className='ldUserData'>
            <h6>{userData.followers}</h6>
            <p>{langData.follower}</p>
          </a>
        </div>
        {userid > 0 ? (
          <ul className="ldMenu">
            <div className='ldMenuGroup'>
              <li><a href="/transactions"><img src={require('../src/assets/icons/nProfile.webp')} /> <p> {langData.add_funds}</p></a></li>
            </div>
            <div className='ldMenuGroup'>
              <li><a href={"/user-detail/" + userid}><img src={require('../src/assets/icons/nProfile.webp')} /> <p> {langData.profile}</p></a></li>
              <li><a href={"/message"}><img src={require('../src/assets/icons/nMessage.webp')} /> <p> {langData.message}</p></a></li>
              <li><a href={"/explore"}><img src={require('../src/assets/icons/explore.webp')} /> <p> {langData.explore}</p></a></li>
              <li><a href="/collections"><img src={require('../src/assets/icons/nCollections.webp')} /> <p> {langData.collections}</p></a></li>
              <li><a href="/transactions"><img src={require('../src/assets/icons/nMoney.webp')} /> <p> {langData.transactions}</p></a></li>
              {userData.isCreator == '1' ? (
                <div>
                  <li><a href="/posts"><img src={require('../src/assets/icons/nCreators.webp')} /> <p> {langData.my_posts}</p></a></li>
                </div>
              ) : (
                <li><a href="/apply"><img src={require('../src/assets/icons/creators.webp')} /> <p> {langData.apply_as_creator}</p></a></li>
              )}
              {userData.isStore == '1' ? (
                <div>
                  <li><a href="/orders"><img src={require('../src/assets/icons/orders.webp')} /> <p> {langData.orders}</p></a></li>
                  <li><a href="/products"><img src={require('../src/assets/icons/product.webp')} /> <p> {langData.my_products}</p></a></li>
                </div>
              ) : ''}
              <li><a href="/profile"><img src={require('../src/assets/icons/filter.webp')} /> <p> {langData.settings}</p></a></li>
            </div>
            {/*<div className='ldMenuGroup'>
            <li><a href="/"><img src={require('../src/assets/icons/cards.webp')} /> <p> My Cards(to subscribe)</p></a></li>
          </div>*/}
            <div className='ldMenuGroup'>
              {/*<li><a onClick={() => chData(userid, 'mode', 'mode', dil)}><img src={require('../src/assets/icons/lightmode.webp')} /> 
            {mode == "dark" ? (<p> {langData.light_mode}</p>):(<p> {langData.dark_mode}</p>)}
            </a></li>*/}
              <li><a href="/message/1"><img src={require('../src/assets/icons/nSupport.webp')} /> <p> {langData.support}</p></a></li>
            </div>
            <li><a onClick={() => logout()}><img src={require('../src/assets/icons/logout.webp')} /> <p> {langData.logout}</p></a></li>
          </ul>
        ) : ''}
      </div>
      <Container>
        <div className='windowBlur' id='windowBlur' onClick={() => ldMenu('close')}></div>
        <Row>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Body langData={langData} />} />
              <Route path="/:guserid" element={<Body langData={langData} />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/:uniqid" element={<Login />} />
              <Route path="/login/:uniqid/:type" element={<Login />} />
              <Route path="/user-detail/:id" element={<User langData={langData} />} />
              <Route path="/explore" element={<Explore langData={langData} />} />
              <Route path="/notification" element={<Notification langData={langData} />} />
              <Route path="/message" element={<Message langData={langData} />} />
              <Route path="/message/:uniqid" element={<Message langData={langData} />} />
              <Route path="/transactions" element={<Transactions langData={langData} />} />
              <Route path="/collections" element={<Collections langData={langData} />} />
              <Route path="/profile" element={<Profile langData={langData} />} />
              <Route path="/post/:id" element={<Post langData={langData} />} />
              <Route path="/posts" element={<Posts langData={langData} />} />
              <Route path="/posts/:uniqid" element={<Posts langData={langData} />} />
              <Route path="/posts/:uniqid/:gpt" element={<Posts langData={langData} />} />
              <Route path="/Apply/" element={<Apply langData={langData} />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/store" element={<Store langData={langData} />} />
              <Route path="/categories" element={<Categories langData={langData} />} />
              <Route path="/categories/:seo" element={<Categories langData={langData} />} />
              <Route path="/categories/:seo/:search" element={<Categories langData={langData} />} />
              <Route path="/product/:id" element={<ItemDetail langData={langData} />} />
              <Route path="/products" element={<Products langData={langData} />} />
              <Route path="/products/:uniqid" element={<Products langData={langData} />} />
              <Route path="/orders/" element={<Orders langData={langData} />} />
              <Route path="/orders/:type" element={<Orders langData={langData} />} />
              <Route path="/orders/:type/:uniqid" element={<Orders langData={langData} />} />
              <Route path="/cart" element={<Cart langData={langData} />} />
              <Route path="/checkout" element={<Checkout langData={langData} />} />
              <Route path="/pages" element={<Pages langData={langData} />} />
              <Route path="/pages/:id" element={<Pages langData={langData} />} />
              <Route path="/renew/" element={<Renew langData={langData} />} />
              <Route path="/renew/:id" element={<Renew langData={langData} />} />
              <Route path="/test/" element={<Test langData={langData} />} />
            </Routes>
          </BrowserRouter>
        </Row>
      </Container>
      <BrevoConversations></BrevoConversations>
    </div>
  );
}

export default App;
